import type { audience as _audience } from '@/params'

export function clearURLErrorParams() {
  const url = new URL(location.href)
  url.searchParams.delete('error')
  url.searchParams.delete('error_description')
  window.history.replaceState({}, '', url)
}

export function getTermsAndPrivacyUrlByAudience(
  audience: typeof _audience,
  options?: {
    isChina?: boolean
  },
): {
  terms: string
  privacy: string
} {
  const { isChina = false } = options ?? {}
  switch (audience) {
    case 'seekrtech.forest':
      return {
        terms: 'https://forestapp.cc/terms/',
        privacy: 'https://forestapp.cc/privacy/',
      }
    case 'seekrtech.forest.next':
      return {
        terms: `https://forest2.seekrtech.com/terms/${isChina ? 'china/' : ''}`,
        privacy: `https://forest2.seekrtech.com/privacy/${isChina ? 'china/' : ''}`,
      }
    case 'seekrtech.daily':
      return {
        terms: 'https://daily-legal-docs.seekrtech.com/terms/',
        privacy: 'https://daily-legal-docs.seekrtech.com/privacy/',
      }
    default:
      throw new Error(
        `Terms & privacy policy URL not set for audience: ${audience}`,
      )
  }
}
