import type { Message } from '@/types/message'

type AccountActionMessage = Extract<Message, { type: 'account_action' }>

type Payload = AccountActionMessage['payload']

export function sendAccountActionMessage(payload: Payload) {
  const message: AccountActionMessage = {
    type: 'account_action',
    payload,
  }
  window.postMessage(message)
}
