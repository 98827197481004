<script lang="ts">
  import { isGlobalLoaderVisible } from '@/stores'
  import { fade } from 'svelte/transition'
  import Spinner from './anim/spinner.svelte'
</script>

{#if $isGlobalLoaderVisible}
  <div
    class="fixed inset-0 z-10 grid place-items-center bg-black/40"
    transition:fade={{ duration: 150 }}
  >
    <Spinner />
  </div>
{/if}
