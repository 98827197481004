import '@/polyfill.js'

import '@/global.css'
import WithOverlayItems from '@/layouts/with-overlay-items.svelte'
import { getLocale } from '@/lib/utils/i18n'
import { createInertiaApp } from '@inertiajs/svelte'
import { init, register, waitLocale } from 'svelte-i18n'

const pages = import.meta.glob('@/pages/**/*.svelte', {
  eager: true,
})

createInertiaApp({
  resolve: (name) => {
    const page = pages[`/pages/${name}.svelte`]
    return {
      default: page.default,
      layout: WithOverlayItems,
    }
  },
  async setup({ el, App, props }) {
    const locale = getLocale()
    register('en', () => import(`@/locales/en.json`))
    register('zh-CN', () => import(`@/locales/zh_CN.json`))
    register('zh-TW', () => import(`@/locales/zh_TW.json`))
    register('pt-BR', () => import(`@/locales/pt_BR.json`))
    register('ar', () => import(`@/locales/ar.json`))
    register('de', () => import(`@/locales/de.json`))
    register('es', () => import(`@/locales/es.json`))
    register('fr', () => import(`@/locales/fr.json`))
    register('it', () => import(`@/locales/it.json`))
    register('ja', () => import(`@/locales/ja.json`))
    register('ko', () => import(`@/locales/ko.json`))
    register('ru', () => import(`@/locales/ru.json`))
    register('tr', () => import(`@/locales/tr.json`))
    register('th', () => import(`@/locales/th.json`))
    await Promise.all([
      init({
        fallbackLocale: 'en',
        initialLocale: locale,
      }),
      waitLocale(locale),
    ])
    new App({
      target: el,
      props,
    })
  },
})

// FIXME: Temp fix for iOS WebView input blur issue
document.addEventListener('touchstart', (event) => {
  if (event.target.nodeName === 'INPUT') return
  if (
    event.target.nodeName === 'DIV' &&
    event.target.contentEditable === 'true'
  ) {
    return
  }
  if (document.activeElement?.nodeName !== 'INPUT') return
  if (document.activeElement?.contentEditable !== 'true') return

  document.activeElement.blur()
})
