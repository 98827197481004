<script lang="ts">
  import { sendAccountActionMessage } from '@/lib/native-messaging/account-action'
  import { Dialog, showDialog } from '@/lib/native-messaging/dialog'
  import {
    logComponentEvent,
    logTextEditEvent,
  } from '@/lib/native-messaging/log-event'
  import { Toast, showToast } from '@/lib/native-messaging/toast'
  import { HTTPError } from '@/lib/utils/error'
  import { afterUpdate } from 'svelte'
  import { _ } from 'svelte-i18n'
  import type { FormEventHandler } from 'svelte/elements'
  import EditIcon from './icons/edit.svelte'

  export let username: string
  export let userId: string
  const MAX_LENGTH = 30
  let draft = username
  let editable: HTMLDivElement
  let isEditing = false

  let abortController = new AbortController()

  afterUpdate(() => {
    if (isEditing) {
      editable.focus()
      logTextEditEvent('start', 'username')
    }
  })

  const handleInput: FormEventHandler<HTMLDivElement> = (event) => {
    const div = event.target as HTMLDivElement
    let content = div.textContent!
    if (content.length > MAX_LENGTH) {
      showToast(Toast.UsernameTooLong)
      draft = content.slice(0, MAX_LENGTH)
      div.textContent = draft
      moveCursorToLastCharacter()
    } else {
      draft = content
    }
  }

  function moveCursorToLastCharacter() {
    const range = document.createRange()
    const selection = window.getSelection()
    if (!selection) return
    range.selectNodeContents(editable)
    range.collapse(false)
    selection.removeAllRanges()
    selection.addRange(range)
  }

  async function handleBlur() {
    logTextEditEvent('end', 'username')
    isEditing = false
    const trimmed = draft.trim()
    if (trimmed === '') {
      showToast(Toast.UsernameRequired)
      draft = username
      editable.textContent = draft
      return
    }
    draft = trimmed
    editable.textContent = draft
    if (draft === username) return

    username = draft

    abortController.abort()
    abortController = new AbortController()
    fetch(`/users/${userId}`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        name: username,
      }),
      signal: abortController.signal,
    })
      .then((response) => {
        if (response.ok === false) {
          throw new HTTPError(response.status, response.statusText)
        }
        sendAccountActionMessage({
          action: 'change_username',
          updated: {
            username,
          },
        })
      })
      .catch((error) => {
        if (error instanceof TypeError) {
          showDialog(Dialog.InternetDisconnected)
        }
        if (error instanceof HTTPError) {
          showDialog({
            type: 'default',
            name: 'unhandled_error_dialog',
            titleKey: 'Unhandled Error',
            contentKey: `Error status code: ${error.status}`,
            primaryBtnKey: 'OK',
          })
        }
      })
  }
</script>

<div class="flex items-center space-x-2">
  <div
    class="editable outline-none"
    placeholder={$_('placeholder_settings_name')}
    bind:this={editable}
    on:beforeinput={() => false}
    contenteditable={isEditing}
    on:focus={moveCursorToLastCharacter}
    on:blur={handleBlur}
    on:input={handleInput}
  >
    {draft}
  </div>
  {#if isEditing === false}
    <button
      class="h-5 w-5 min-w-[20px] text-content-tertiary"
      on:click={() => {
        logComponentEvent({
          type: 'btn',
          appAction: 'click',
          componentName: 'edit_username',
        })
        isEditing = true
      }}
    >
      <EditIcon />
    </button>
  {/if}
</div>

<style>
  .editable:empty:before {
    content: attr(placeholder);
    color: #555;
  }
</style>
