<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<mask id="mask0_695_44969" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
<rect x="16" width="16" height="16" transform="rotate(90 16 0)" fill="url(#pattern0_695_44969)"/>
</mask>
<g mask="url(#mask0_695_44969)">
<rect x="16" width="16" height="16" transform="rotate(90 16 0)" fill="#A4A4A4"/>
</g>
<defs>
<pattern id="pattern0_695_44969" patternContentUnits="objectBoundingBox" width="1" height="1">
<use xlink:href="#image0_695_44969" transform="scale(0.0104167)"/>
</pattern>
<image id="image0_695_44969" width="96" height="96" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAACxKAAAsSgF3enRNAAAB/klEQVR4nO2dy01DQRAEB8QdQiADUjEZkAFoAxmZSHAoJgNCgAjMwSeG+3ZPT1cCu3bJ9d5+JN9cLpcwOG793WOxADAWAMYCwFgAGAsAYwFgLACMBYCxADAWAMYCwFgAGAsAYwFgLACMBYCxADAWAMYCwFAIyMxTZh4IprIdll/AQ0R8ZOaRYC5bYUvQa2aeM/ORYC5bYHwGPEXEeUqSWB/C91OSxP4WJJ+kDq+h0knqsg6QTVK3hZhckjquhKWS1HUrQiZJ3feC2idJYTOudZJUdkPbJkltO7pdkhTPA1olSfVApk2S1E/E6JM04UiSOklTzoRpkzTtUJ4uSRNvRVAlaeq1FJokTb8XBE/SdAGBTpIFXIElyQL+sj1JFgDmbvSn/8/7Wutt54AWcOUnIl7WWqfdA1tAxGdEHNZaX4jBpwvYnpzKVAGw5FQmCoAmpzJNADw5lSkCaJJTmSCAKjkVdQF0yamoCqBNTkVRAHVyKmoC6JNTURHQJjkVBQGtklPpLqBdcipdBbRNTqWjgNbJqXQT0D45lS4CZJJT6SBAKjkVdgFyyamwCpBNToVRgHRyKmwC5JNTYRHwHRHPE5JT8V8ZgvHdUDAWAMYCwFgAGAsAYwFgLACMBYCxADAWAMYCwFgAGAsAYwFgLACMBYCxADAWAMYCwFgAkoj4Bb6BtdM/FVRWAAAAAElFTkSuQmCC"/>
</defs>
</svg>
