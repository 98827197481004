<script lang="ts">
  import Spinner from '@/components/anim/spinner.svelte'
  import ForgotPasswordButton from '@/components/forgot-password-button.svelte'
  import TextField from '@/components/text-field.svelte'
  import Footer from '@/layouts/footer.svelte'
  import Main from '@/layouts/main.svelte'
  import { sendAccountActionMessage } from '@/lib/native-messaging/account-action'
  import { Dialog, showDialog } from '@/lib/native-messaging/dialog'
  import { logComponentEvent } from '@/lib/native-messaging/log-event'
  import { dispatchWebViewAction } from '@/lib/native-messaging/web-view-action'
  import { DeleteAccountForm } from '@/schema'
  import type { FormStatus } from '@/types/form'
  import { setContext } from 'svelte'
  import { _ } from 'svelte-i18n'
  import { writable } from 'svelte/store'

  export let user = {
    email: '',
  }

  const params = new URLSearchParams(location.search)
  const version = params.get('version')

  let isLoading = false

  const formStore = writable<FormStatus>({
    password: {
      touched: false,
      error: '',
    },
  })
  setContext('formStore', formStore)

  $: allTouched = Object.values($formStore).every((status) => status.touched)
  $: hasError = Object.values($formStore).some((status) => !!status.error)

  async function handleSubmit(
    event: SubmitEvent & {
      currentTarget: EventTarget & HTMLFormElement
    },
  ) {
    const data = {
      password: event.currentTarget.password.value,
    }
    const result = DeleteAccountForm.safeParse(data)
    if (result.success === false) {
      result.error.errors.forEach((error) => {
        const [target] = error.path
        formStore.update((status) => {
          return {
            ...status,
            [target]: {
              touched: true,
              error: $_(error.message),
            },
          }
        })
      })
      return
    }
    isLoading = true
    try {
      const res = await fetch('/delete_user', {
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      if (!res.ok) throw res.status
      sendAccountActionMessage({ action: 'delete' })
      dispatchWebViewAction({ action: 'end_session' })
    } catch (err) {
      if (err instanceof TypeError) {
        showDialog(Dialog.InternetDisconnected)
        return
      }
      switch (err) {
        case 403:
          showDialog(Dialog.WrongPassword)
          break
        default:
          showDialog({
            type: 'default',
            name: 'test',
            titleKey: 'Error',
            contentKey: `Code: ${err}`,
            primaryBtnKey: 'OK',
          })
          break
      }
    } finally {
      isLoading = false
    }
  }
</script>

<form class="flex h-full flex-col" on:submit|preventDefault={handleSubmit}>
  <Main>
    <p class="text-content-secondary text-body-lg">
      {$_(
        version === '1.0.3'
          ? 'delete_account_content_no_subscription'
          : 'delete_account_content',
        { values: { email: user.email } },
      )}
    </p>
    <TextField
      name="password"
      type="password"
      placeholder={$_('placeholder_password')}
      dataFieldName="password"
      schema={DeleteAccountForm.shape.password}
    />
    <div class="text-right text-body-md">
      <ForgotPasswordButton email={user.email} />
    </div>
  </Main>
  <Footer>
    <button
      type="submit"
      class="btn bg-warning"
      disabled={isLoading || !allTouched || hasError}
      on:click={() => {
        logComponentEvent({
          type: 'btn',
          appAction: 'click',
          componentName: 'delete_account',
        })
      }}
    >
      {#if isLoading}
        <Spinner />
      {/if}
      <span>{$_('btn_delete_account')}</span>
    </button>
  </Footer>
</form>
