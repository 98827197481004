<script lang="ts">
  import Spinner from '@/components/anim/spinner.svelte'
  import ResendSection from '@/components/resend-section.svelte'
  import TextField from '@/components/text-field.svelte'
  import Footer from '@/layouts/footer.svelte'
  import Main from '@/layouts/main.svelte'
  import { grantAccess, verifyEmail } from '@/lib/api'
  import { sendAccountActionMessage } from '@/lib/native-messaging/account-action'
  import { Dialog, showDialog } from '@/lib/native-messaging/dialog'
  import {
    logComponentEvent,
    logGeneralEvent,
  } from '@/lib/native-messaging/log-event'
  import { Toast, showToast } from '@/lib/native-messaging/toast'
  import { dispatchWebViewAction } from '@/lib/native-messaging/web-view-action'
  import { redirect } from '@/lib/oauth/redirect'
  import { params, redirectUri } from '@/params'
  import { EmailValidationForm } from '@/schema'
  import type { FormStatus } from '@/types/form'
  import { setContext } from 'svelte'
  import { _ } from 'svelte-i18n'
  import { writable } from 'svelte/store'

  export let user: { email: string }

  const from = params.get('from') as '/signup' | '/signin' | '/change_email'
  const newEmail = params.get('email')
  const isGuest = !!params.get('convert_token')

  let emailTokenType: 'signup' | 'change_email'
  switch (from) {
    case '/change_email':
      emailTokenType = 'change_email'
      break
    case '/signup':
      emailTokenType = 'signup'
      break
    case '/signin':
      emailTokenType = 'signup'
      break
    default:
      throw new Error('Invalid from')
  }

  const formStore = writable<FormStatus>({
    code: {
      touched: false,
      error: '',
    },
  })
  setContext('formStore', formStore)

  $: allTouched = Object.values($formStore).every((status) => status.touched)
  $: hasError = Object.values($formStore).some((status) => !!status.error)

  let isLoading = false

  async function submitGrantingAccess() {
    isLoading = true
    const [result, payload] = await grantAccess()
    isLoading = false
    if (result !== 'SUCCESS') {
      showDialog(Dialog.RetryDialog, (action) => {
        if (action === Dialog.RetryDialog.primaryBtnAction) {
          submitGrantingAccess()
        }
      })
      return
    }
    redirect(redirectUri, { ...Object.fromEntries(params), ...payload })

    if (from === '/change_email') {
      sendAccountActionMessage({
        action: 'change_email',
        updated: {
          email: newEmail ?? '',
        },
      })
      showToast(Toast.ChangeEmailSuccess)
      dispatchWebViewAction({ action: 'dismiss' })
      return
    }
    if (isGuest) {
      if (from === '/signin') {
        sendAccountActionMessage({ action: 'guest_login_as_existed_user' })
      }
      if (from === '/signup') {
        sendAccountActionMessage({ action: 'guest_complete_signup' })
      }
    }
    dispatchWebViewAction({ action: 'end_session' })
  }

  async function handleSubmit(
    event: SubmitEvent & {
      currentTarget: EventTarget & HTMLFormElement
    },
  ) {
    const parsed = EmailValidationForm.safeParse(
      Object.fromEntries(new FormData(event.currentTarget)),
    )
    if (parsed.success === false) {
      parsed.error.errors.forEach((error) => {
        const [path] = error.path
        formStore.update((status) => {
          return {
            ...status,
            [path]: {
              touched: true,
              error: $_(error.message),
            },
          }
        })
      })
      return
    }

    isLoading = true
    const [result, payload] = await verifyEmail(parsed.data.code)
    isLoading = false

    switch (result) {
      case 'SUCCESS':
        if (from === '/signup') {
          logGeneralEvent('seekrtech_signup', { is_guest: isGuest.toString() })
        }
        submitGrantingAccess()
        break
      case 'INVALID_CODE':
        showDialog(Dialog.WrongVerificationCode)
        break
      case 'NETWORK_ERROR':
        showDialog(Dialog.InternetDisconnected)
        break
      case 'UNHANDLED_ERROR':
        showDialog(Dialog.UnhandledError(payload.message))
        break
      case 'UNHANDLED_HTTP_ERROR':
        showDialog(Dialog.UnhandledHTTPError(payload))
        break
    }
  }
</script>

<form class="flex h-full flex-col" on:submit|preventDefault={handleSubmit}>
  <Main>
    <p class="text-content-secondary text-body-lg">
      {$_('verification_code_sent', {
        values: {
          email: from === '/change_email' ? newEmail : user.email,
        },
      })}
    </p>
    <TextField
      name="code"
      placeholder={$_('placeholder_verification_code')}
      schema={EmailValidationForm.shape.code}
      dataFieldName="verification_code"
    />
    <ResendSection
      tokenType={emailTokenType}
      hasSentEmailFromServer={from !== '/signin'}
    />
  </Main>
  <Footer>
    <button
      type="submit"
      class="btn"
      disabled={isLoading || !allTouched || hasError}
      on:click={() => {
        logComponentEvent({
          type: 'btn',
          appAction: 'click',
          componentName:
            from === '/change_email'
              ? 'complete_email_edit'
              : 'complete_signup',
        })
      }}
    >
      {#if isLoading}
        <Spinner />
      {/if}
      <span
        >{$_(
          from === '/change_email' ? 'btn_change_email' : 'btn_signup',
        )}</span
      >
    </button>
  </Footer>
</form>
