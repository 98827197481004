<svg
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g id="clarity:eye-hide-line" clip-path="url(#clip0_20479_9054)">
    <path
      id="Vector"
      d="M13.9945 11.3334C14.154 10.9071 14.235 10.4553 14.2334 10.0001C14.2334 8.98931 13.8318 8.01993 13.1171 7.30521C12.4024 6.59049 11.433 6.18896 10.4223 6.18896C9.97264 6.18949 9.52674 6.27039 9.10559 6.42785L10 7.35008C10.136 7.32832 10.2735 7.31718 10.4111 7.31674C11.1258 7.31525 11.8121 7.5966 12.32 8.09935C12.828 8.6021 13.1164 9.28541 13.1223 10.0001C13.1218 10.1378 13.1107 10.2752 13.0889 10.4112L13.9945 11.3334Z"
      fill="#8A8B8D"
    />
    <path
      id="Vector_2"
      d="M19.05 9.73891C17.1778 6.27779 13.8944 4.18335 10.2611 4.18335C9.27184 4.18567 8.28923 4.34506 7.34998 4.65557L8.24442 5.55557C8.90323 5.38531 9.58064 5.2976 10.2611 5.29446C13.3889 5.29446 16.2333 7.0389 17.9278 9.97779C17.3062 11.0681 16.4822 12.0298 15.5 12.8111L16.2889 13.6C17.4256 12.683 18.3696 11.5501 19.0666 10.2667L19.2111 10L19.05 9.73891Z"
      fill="#8A8B8D"
    />
    <path
      id="Vector_3"
      d="M2.7056 3.21108L5.18338 5.68886C3.61722 6.69725 2.33721 8.09206 1.46671 9.73886L1.32227 9.99997L1.46671 10.2666C3.33893 13.7277 6.62226 15.8222 10.2556 15.8222C11.6737 15.8219 13.0735 15.5009 14.35 14.8833L17.1278 17.6611L18.1 16.8277L3.6556 2.3833L2.7056 3.21108ZM8.12227 8.62774L11.8167 12.3222C11.3993 12.5806 10.9187 12.719 10.4278 12.7222C10.0711 12.7222 9.71793 12.6517 9.38859 12.5147C9.05924 12.3777 8.76024 12.1769 8.50879 11.9239C8.25733 11.6709 8.05839 11.3706 7.92341 11.0405C7.78843 10.7103 7.72007 10.3567 7.72227 9.99997C7.72853 9.51472 7.86681 9.04035 8.12227 8.62774ZM7.31671 7.82219C6.79695 8.55519 6.55334 9.44846 6.629 10.3438C6.70466 11.2392 7.09471 12.079 7.7301 12.7144C8.36549 13.3497 9.20522 13.7398 10.1006 13.8155C10.996 13.8911 11.8893 13.6475 12.6223 13.1277L13.5112 14.0166C12.4821 14.4565 11.3747 14.6833 10.2556 14.6833C7.12782 14.6833 4.28338 12.9389 2.58893 9.99997C3.4021 8.56009 4.57322 7.35452 5.98893 6.49997L7.31671 7.82219Z"
      fill="#8A8B8D"
    />
  </g>
  <defs>
    <clipPath id="clip0_20479_9054">
      <rect width="20" height="20" fill="currentColor" />
    </clipPath>
  </defs>
</svg>
