<svg
  width="11"
  height="9"
  viewBox="0 0 11 9"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    id="Vector 1386"
    d="M1 3.99992L4 6.99992L9.66667 1.33325"
    stroke="white"
    stroke-width="2"
    stroke-linecap="round"
  />
</svg>
