import { isStandalone } from '@/params'

type Payload =
  | {
      action: 'push' | 'modal'
      url: string
      navBarTitle: string
      navBarVisible: boolean
    }
  | {
      action: 'pop' | 'pop_to_root' | 'end_session' | 'dismiss'
    }

export function dispatchWebViewAction(payload: Payload) {
  const message = {
    type: 'web_view_action',
    payload,
  }
  window.postMessage(message)

  if (isStandalone) {
    if (payload.action === 'push' || payload.action === 'modal') {
      location.assign(payload.url)
    }
    if (payload.action === 'pop') {
      history.back()
    }
    if (payload.action === 'pop_to_root') {
      location.assign('/')
    }
  }
}
