<script lang="ts">
  import { logComponentEvent } from '@/lib/native-messaging/log-event'
  import { HTTPError } from '@/lib/utils/http'
  import { resolveURL } from '@/lib/utils/url'
  import { afterUpdate } from 'svelte'
  import { _ } from 'svelte-i18n'

  export let tokenType: 'signup' | 'change_email' | 'reset_password'
  export let hasSentEmailFromServer: boolean

  let endpoint: string
  switch (tokenType) {
    case 'signup':
      endpoint = '/resend_signup_email'
      break
    case 'change_email':
      endpoint = '/resend_change_email'
      break
    case 'reset_password':
      endpoint = '/reset_token'
  }

  const params = new URLSearchParams(location.search)
  const language = params.get('language') || 'en'

  const DEFAULT_COUNT_DOWN = 30

  let section: HTMLParagraphElement
  let countDown = hasSentEmailFromServer ? DEFAULT_COUNT_DOWN : 0
  async function sendEmail() {
    try {
      const response = await fetch(resolveURL(endpoint), {
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
          'accept-language': language.replace('_', '-'),
        },
      })
      if (response.ok === false) {
        throw new HTTPError(response.status, response.statusText)
      }
      countDown = DEFAULT_COUNT_DOWN
    } catch (error) {
      countDown = -1
    } finally {
      hasSentEmailFromServer = true
    }
  }
  if (hasSentEmailFromServer === false) {
    sendEmail()
  }

  // Trigger update
  $: {
    if (countDown === -1) {
      countDown = 0
    }
  }

  let interval: NodeJS.Timeout
  $: {
    if (countDown === DEFAULT_COUNT_DOWN) {
      interval = setInterval(() => {
        if (countDown === 0) {
          clearInterval(interval)
          return
        }
        countDown -= 1
      }, 1000)
    }
  }

  afterUpdate(() => {
    const resendBtn = section.querySelector<HTMLButtonElement>('#resend')
    if (!resendBtn) return
    resendBtn.disabled = false
    resendBtn.onclick = async () => {
      logComponentEvent({
        type: 'text',
        appAction: 'click',
        componentName: 'resend_confirmation',
      })
      resendBtn.disabled = true
      await sendEmail()
    }
  })
</script>

<p class="text-right text-content-tertiary text-body-md" bind:this={section}>
  {#if hasSentEmailFromServer}
    {#if countDown > 0}
      {$_('verification_code_resend_countdown', {
        values: {
          second: `${countDown}`,
        },
      })}
    {:else}
      {@html $_('verification_code_resend', {
        values: {
          resend: `<button id="resend" type="button" class="text-secondary underline disabled:opacity-50">${$_(
            'resend',
          )}</button>`,
        },
      })}
    {/if}
  {/if}
</p>
