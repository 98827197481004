<script lang="ts">
  import Spinner from '@/components/anim/spinner.svelte'
  import TextField from '@/components/text-field.svelte'
  import Footer from '@/layouts/footer.svelte'
  import Main from '@/layouts/main.svelte'
  import { peekUser } from '@/lib/api'
  import { Dialog, showDialog } from '@/lib/native-messaging/dialog'
  import { logComponentEvent } from '@/lib/native-messaging/log-event'
  import { signInWithLegacy } from '@/lib/native-messaging/sign-in-with-legacy'
  import { dispatchWebViewAction } from '@/lib/native-messaging/web-view-action'
  import { resolveURL } from '@/lib/utils/url'
  import { PeekEmailForm } from '@/schema'
  import type { FormStatus } from '@/types/form'
  import { setContext } from 'svelte'
  import { _ } from 'svelte-i18n'
  import { writable } from 'svelte/store'

  const params = new URLSearchParams(window.location.search)
  const isGuest = !!params.get('convert_token')

  const formStore = writable<FormStatus>({})
  setContext('formStore', formStore)

  $: allTouched = Object.values($formStore).every((status) => status.touched)
  $: hasError = Object.values($formStore).some((status) => !!status.error)

  let isLoading = false
  async function handleSubmit(
    event: SubmitEvent & {
      currentTarget: EventTarget & HTMLFormElement
    },
  ) {
    const data = {
      email: event.currentTarget.email.value,
    }
    const parseResult = PeekEmailForm.safeParse(data)
    if (parseResult.success === false) {
      parseResult.error.errors.forEach((error) => {
        const [key] = error.path
        formStore.update((status) => {
          return {
            ...status,
            [key]: {
              touched: true,
              error: $_(error.message),
            },
          }
        })
      })
      return
    }

    isLoading = true
    const [result, resultData] = await peekUser(data.email)
    isLoading = false
    switch (result) {
      case 'LEGACY_USER_FOUND':
        if (isGuest) {
          showDialog(Dialog.GuestDataOverwritingWarning, (action) => {
            if (
              action === Dialog.GuestDataOverwritingWarning.primaryBtnAction
            ) {
              signInWithLegacy(data.email)
              dispatchWebViewAction({ action: 'end_session' })
            }
          })
        } else {
          signInWithLegacy(data.email)
          dispatchWebViewAction({ action: 'end_session' })
        }
        break
      case 'USER_FOUND':
        if (isGuest) {
          showDialog(Dialog.GuestDataOverwritingWarning, (action) => {
            if (
              action === Dialog.GuestDataOverwritingWarning.primaryBtnAction
            ) {
              dispatchWebViewAction({
                action: 'push',
                url: resolveURL('/signin', { email: data.email }).toString(),
                navBarTitle: $_('signin_title'),
                navBarVisible: true,
              })
            }
          })
        } else {
          dispatchWebViewAction({
            action: 'push',
            url: resolveURL('/signin', { email: data.email }).toString(),
            navBarTitle: $_('signin_title'),
            navBarVisible: true,
          })
        }
        break
      case 'USER_NOT_FOUND':
        dispatchWebViewAction({
          action: 'push',
          url: resolveURL('/signup', { email: data.email }).toString(),
          navBarTitle: $_('signup_title'),
          navBarVisible: true,
        })
        break
      case 'NETWORK_ERROR':
        showDialog(Dialog.InternetDisconnected)
        break
      case 'UNHANDLED_HTTP_ERROR':
        showDialog({
          type: 'default',
          name: 'unhandled_http_error',
          titleKey: 'Unhandled HTTP Error',
          contentKey: `Code: ${resultData}`,
          primaryBtnKey: 'OK',
        })
        break
      case 'UNHANDLED_ERROR':
        showDialog({
          type: 'default',
          name: 'unhandled_error',
          titleKey: 'Unhandled Error',
          contentKey: `Messsage: ${resultData.message}`,
          primaryBtnKey: 'OK',
        })
        break
    }
  }
</script>

<form
  class="flex h-full flex-col"
  on:submit|preventDefault={handleSubmit}
  novalidate
>
  <Main>
    <p class="text-content-secondary text-body-lg">
      {$_('signup_or_signin_email_title')}
    </p>
    <TextField
      type="email"
      name="email"
      placeholder={'seekrtech@example.com'}
      schema={PeekEmailForm.shape.email}
      dataFieldName="entry_email"
    />
  </Main>
  <Footer>
    <button
      class="btn"
      disabled={isLoading || !allTouched || hasError}
      on:click={() => {
        logComponentEvent({
          type: 'btn',
          appAction: 'click',
          componentName: 'continue_entry',
        })
      }}
    >
      {#if isLoading}
        <Spinner />
      {/if}
      <span>{$_('btn_next')}</span>
    </button>
  </Footer>
</form>
