<svg
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#clip0_20479_10122)">
    <path
      d="M18.6778 9.73915C16.8056 6.27804 13.5222 4.18359 9.8889 4.18359C6.25557 4.18359 2.96668 6.27804 1.11112 9.73915L0.955566 10.0003L1.10001 10.2669C2.97223 13.728 6.25557 15.8225 9.8889 15.8225C13.5222 15.8225 16.8111 13.7558 18.6778 10.2669L18.8222 10.0003L18.6778 9.73915ZM9.8889 14.6836C6.76112 14.6836 3.8889 12.9391 2.22223 10.0003C3.8889 7.06137 6.76112 5.31693 9.8889 5.31693C13.0167 5.31693 15.8556 7.06693 17.55 10.0003C15.8556 12.9391 13.0111 14.6836 9.8889 14.6836Z"
      fill="#8A8B8D"
    />
    <path
      d="M10.05 6.20518C9.29675 6.21068 8.56201 6.43928 7.93859 6.86211C7.31517 7.28495 6.83104 7.88305 6.54734 8.58087C6.26364 9.27869 6.1931 10.0449 6.34463 10.7828C6.49616 11.5207 6.86296 12.1972 7.39871 12.7267C7.93446 13.2562 8.61512 13.6151 9.35472 13.7581C10.0943 13.901 10.8597 13.8215 11.5542 13.5297C12.2486 13.2379 12.8411 12.7469 13.2566 12.1186C13.6722 11.4903 13.8922 10.7529 13.8889 9.99962C13.8867 9.4982 13.7856 9.00213 13.5914 8.53986C13.3971 8.07758 13.1136 7.65818 12.757 7.30568C12.4003 6.95319 11.9777 6.67453 11.5132 6.48569C11.0486 6.29684 10.5514 6.20152 10.05 6.20518ZM10.05 12.7163C9.51794 12.7108 8.99934 12.5482 8.55936 12.249C8.11938 11.9497 7.77764 11.5271 7.57705 11.0343C7.37646 10.5414 7.32597 10.0003 7.43192 9.47882C7.53787 8.95736 7.79554 8.47884 8.17256 8.10335C8.54959 7.72786 9.02916 7.47215 9.55104 7.36833C10.0729 7.2645 10.6138 7.3172 11.1059 7.5198C11.5979 7.7224 12.0191 8.06587 12.3166 8.50707C12.614 8.94827 12.7745 9.46753 12.7778 9.99962C12.7793 10.3577 12.7097 10.7126 12.573 11.0436C12.4363 11.3746 12.2352 11.6751 11.9815 11.9278C11.7277 12.1806 11.4264 12.3804 11.0948 12.5157C10.7632 12.6511 10.4081 12.7192 10.05 12.7163Z"
      fill="#8A8B8D"
    />
  </g>
  <defs>
    <clipPath id="clip0_20479_10122">
      <rect width="20" height="20" fill="currentColor" />
    </clipPath>
  </defs>
</svg>
