import type { Message } from '@/types/message'

export function signInWithLegacy(email: string) {
  window.postMessage({
    type: 'sign_in_with_legacy',
    payload: {
      email,
    },
  } satisfies Message)
}
