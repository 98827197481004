<script lang="ts">
  import landing from '@/assets/images/landing.png'
  import AppleIcon from '@/components/icons/apple.svelte'
  import Email from '@/components/icons/email.svelte'
  import Google from '@/components/icons/google.svelte'
  import ServerSelect from '@/components/server-select.svelte'
  import SignInButton from '@/components/ui/sign-in-button.svelte'
  import TextButton from '@/components/ui/text-button.svelte'
  import { signInAsGuest, verifyGoogleIdToken } from '@/lib/api'
  import { clearURLErrorParams } from '@/lib/helpers'
  import { Dialog, showDialog } from '@/lib/native-messaging/dialog'
  import {
    logComponentEvent,
    logGeneralEvent,
  } from '@/lib/native-messaging/log-event'
  import { signOnWithThirdParty } from '@/lib/native-messaging/third-party-sign-on'
  import { dispatchWebViewAction } from '@/lib/native-messaging/web-view-action'
  import { redirect } from '@/lib/oauth/redirect'
  import { scriptLoad } from '@/lib/utils/script-load'
  import { resolveURL } from '@/lib/utils/url'
  import { convertToken, params, platform, redirectUri } from '@/params'
  import { _ } from 'svelte-i18n'

  const isChina = location.href.includes('upwardsware')

  // Handle redirect from SSO flow
  const error = params.get('error') as null | '401' | '403' | '410'
  if (error) {
    switch (error) {
      case '401':
        showDialog({
          type: 'default',
          name: 'id_token_validation_error_dialog',
          titleKey: 'ID Token Validation Error',
          contentKey: `Message: ${params.get('error_description')}`,
          primaryBtnKey: 'OK',
        })
        break
      case '403':
        showDialog(Dialog.WrongServer)
        break
      case '410':
        showDialog(Dialog.LegacyUserFound)
        break
      default:
        showDialog(Dialog.UnhandledHTTPError(Number(error)))
        break
    }
    clearURLErrorParams()
  }
  if (!!params.get('code')) {
    logGeneralEvent('seekrauth_login', {
      is_guest: (!!convertToken).toString(),
    })
    redirect(redirectUri, { ...Object.fromEntries(params) })
    dispatchWebViewAction({ action: 'end_session' })
  }

  function handleSignInWithEmailBtnClick() {
    logComponentEvent({
      type: 'btn',
      appAction: 'click',
      componentName: 'email_signup',
    })
    dispatchWebViewAction({
      action: 'push',
      url: resolveURL('/signup_or_signin').toString(),
      navBarTitle: $_('signup_or_signin_title'),
      navBarVisible: true,
    })
  }

  function composeSsoState() {
    return JSON.stringify({
      ...Object.fromEntries(params),
      redirect_uri: new URL(location.href).toString(),
    })
  }

  async function handleSignInWithGoogleBtnClick() {
    logComponentEvent({
      type: 'btn',
      appAction: 'click',
      componentName: 'google_signup',
    })
    if (!redirectUri) {
      alert('Error: `redirect_uri` URL param is not provided')
      return
    }
    const idToken = await signOnWithThirdParty({ provider: 'google' })
    if (idToken === 'null') {
      showDialog(Dialog.RetryDialog)
      return
    }
    const [result, payload] = await verifyGoogleIdToken({
      idToken,
      state: composeSsoState(),
    })
    switch (result) {
      case 'REDIRECT':
        location.href = payload
        break
      case 'NETWORK_ERROR':
        showDialog(Dialog.InternetDisconnected)
        break
      case 'UNHANDLED_ERROR':
        showDialog(Dialog.UnhandledError(payload.message))
        break
    }
  }

  let isSigningInWithApple = false
  const appleIdScript = scriptLoad()
  const loadingAppleScript = appleIdScript.loading.then(() => {
    const url = new URL(location.href)
    url.pathname = '/sso_callbacks/apple'
    url.search = ''

    window.AppleID.auth.init({
      clientId: 'com.seekrtech.auth',
      scope: 'name email',
      redirectURI: url.toString(),
      state: composeSsoState(),
    })
  })
  async function handleSignInWithAppleBtnClick() {
    isSigningInWithApple = true
    logComponentEvent({
      type: 'btn',
      appAction: 'click',
      componentName: 'apple_signup',
    })
    await loadingAppleScript
    try {
      const data = await window.AppleID.auth.signIn()
      console.log(data)
    } catch (err) {
      console.log(err)
    }
  }

  let isLoading = false
  async function handleSignInAsGuestBtnClick() {
    isLoading = true
    logComponentEvent({
      type: 'btn',
      appAction: 'click',
      componentName: 'guest_signup',
    })
    const [result, data] = await signInAsGuest()
    isLoading = false
    switch (result) {
      case 'SUCCESS':
        redirect(redirectUri, { ...Object.fromEntries(params), ...data })
        dispatchWebViewAction({ action: 'end_session' })
        break
      case 'NETWORK_ERROR':
        showDialog(Dialog.InternetDisconnected)
        break
      case 'UNHANDLED_HTTP_ERROR':
        showDialog(Dialog.UnhandledHTTPError(data))
        break
      case 'UNHANDLED_ERROR':
        showDialog(Dialog.UnhandledError(data.message))
        break
    }
  }
</script>

<svelte:head>
  <!-- https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/configuring_your_webpage_for_sign_in_with_apple#3235722 -->
  <script
    type="text/javascript"
    src="https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
    on:load={appleIdScript.onLoad}
    on:error={appleIdScript.onError}
  ></script>
</svelte:head>

<div class="flex h-full flex-col">
  <div class="relative max-h-[396px] min-h-0 flex-1">
    <img src={landing} alt="landing" class="h-full object-cover" />
  </div>
  <div class="mt-auto flex flex-col gap-[30px] px-9 pb-[50px]">
    <div class="text-content-primary">
      <h1 class="text-[24px] font-bold text-headline-md">
        {$_('login_home_title')}
      </h1>
      <p class="mt-[5px] text-[14px]">
        {$_('login_home_subtitle')}
      </p>
      <ServerSelect />
    </div>
    <div class="flex flex-col gap-4">
      {#if platform === 'ios'}
        <SignInButton
          on:click={handleSignInWithAppleBtnClick}
          disabled={isSigningInWithApple}
        >
          <AppleIcon slot="icon" />
          <span slot="text">{$_('login_btn_thirdparty_apple')}</span>
        </SignInButton>
      {/if}
      {#if !isChina && platform === 'android'}
        <SignInButton on:click={handleSignInWithGoogleBtnClick}>
          <Google slot="icon" />
          <span slot="text">{$_('login_btn_thirdparty_google')}</span>
        </SignInButton>
      {/if}
      <SignInButton on:click={handleSignInWithEmailBtnClick}>
        <Email slot="icon" />
        <span slot="text">{$_('login_btn_seekrauth')}</span>
      </SignInButton>
      <p class="text-[16px] text-content-secondary">{$_('login_reminder')}</p>
      {#if convertToken === null}
        <TextButton
          class="self-start text-secondary"
          on:click={handleSignInAsGuestBtnClick}
          disabled={isLoading}
        >
          {$_('login_guest')}
        </TextButton>
      {/if}
    </div>
  </div>
</div>
