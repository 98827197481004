<script lang="ts">
  import { getFormStatus } from '@/context/form-status'
  import {
    logComponentEvent,
    logDatePickedEvent,
  } from '@/lib/native-messaging/log-event'
  import { clsx } from 'clsx'
  import { onDestroy, onMount } from 'svelte'
  import { _ } from 'svelte-i18n'
  import type { HTMLInputAttributes } from 'svelte/elements'
  import type { ZodTypeAny } from 'zod'

  interface $$Props extends HTMLInputAttributes {
    name: string
    placeholder?: string
    note?: string
    schema?: ZodTypeAny | null
  }

  export let name: string
  export let placeholder = ''
  export let note = ''
  export let schema: ZodTypeAny | null = null

  const { formStatus, Field } = getFormStatus()

  const field = new Field(name)

  onMount(() => {
    field.init()
  })
  onDestroy(() => {
    field.remove()
  })

  let inputElement: HTMLInputElement
  let inputVal = ''
  let isFocused = false

  function handleFocus() {
    isFocused = true
    field.touch()
  }

  function handleBlur() {
    isFocused = false
  }

  function handleClick() {
    logComponentEvent({
      type: 'time',
      appAction: 'show',
      componentName: 'birth_year_month',
    })
    inputElement.showPicker()
  }

  function handleChange(
    event: Event & {
      currentTarget: HTMLInputElement
    },
  ) {
    const { value } = event.currentTarget
    if (value) {
      logDatePickedEvent(value)
    }

    if (schema) {
      const result = schema.safeParse(value)
      if (result.success) {
        field.clearError()
      } else {
        result.error.errors.forEach((err) => {
          field.setError($_(err.message))
        })
      }
    }
  }
</script>

<div class="relative">
  <input
    {...$$restProps}
    data-1p-ignore
    bind:this={inputElement}
    class={clsx(
      'h-[45px] w-full appearance-none rounded-[16px] bg-element-input px-[12px] text-left text-content-primary outline-[1px] outline-offset-[-1px] text-body-md placeholder:text-content-placeholder',
      isFocused && 'outline outline-secondary',
      $formStatus[name]?.error && 'outline outline-warning',
    )}
    {name}
    type="date"
    bind:value={inputVal}
    on:focus={handleFocus}
    on:blur={handleBlur}
    on:change={handleChange}
    on:click={handleClick}
  />
  {#if $formStatus[name]?.error}
    <p class="mt-[8px] text-right text-warning text-label-md">
      {$formStatus[name]?.error}
    </p>
  {/if}
  {#if note && isFocused}
    <p class="mt-[8px] text-right text-content-tertiary text-label-md">
      {note}
    </p>
  {/if}
  {#if !inputVal && placeholder}
    <div
      class="pointer-events-none absolute left-3 top-3 text-content-placeholder text-body-md"
    >
      {placeholder}
    </div>
  {/if}
</div>
