<script lang="ts">
  import AgeScreenCodeDialog from '@/components/age-screen-code-dialog.svelte'
  import Spinner from '@/components/anim/spinner.svelte'
  import DateInput from '@/components/date-input.svelte'
  import CheckMark from '@/components/icons/check-mark.svelte'
  import TextField from '@/components/text-field.svelte'
  import { createFormStatusContext, getFormStatus } from '@/context/form-status'
  import Footer from '@/layouts/footer.svelte'
  import Main from '@/layouts/main.svelte'
  import { signUp } from '@/lib/api'
  import { getTermsAndPrivacyUrlByAudience } from '@/lib/helpers'
  import { Dialog, showDialog } from '@/lib/native-messaging/dialog'
  import { logComponentEvent } from '@/lib/native-messaging/log-event'
  import { dispatchWebViewAction } from '@/lib/native-messaging/web-view-action'
  import { resolveURL } from '@/lib/utils/url'
  import { audience } from '@/params'
  import { SignUpForm, SignUpFormRefined } from '@/schema'
  import clsx from 'clsx'
  import { onMount } from 'svelte'
  import { _ } from 'svelte-i18n'

  const params = new URLSearchParams(location.search)
  const region = params.get('region')
  const convertToken = params.get('convert_token')
  const language = params.get('language') || 'en'

  let isAgreed = false
  let isLoading = false
  let form: HTMLFormElement
  let signUpSubmission:
    | (SubmitEvent & {
        currentTarget: EventTarget & HTMLFormElement
        ageScreenCode?: string
      })
    | null = null

  const formStatus = createFormStatusContext()
  const { allTouched, hasError, Field } = getFormStatus({ formStatus })

  function handleAgeScreenCodeSubmit(code: string) {
    if (signUpSubmission) {
      signUpSubmission.ageScreenCode = code
      form.dispatchEvent(signUpSubmission)
    }
  }

  function handleAgeScreeningDialogClose() {
    signUpSubmission = null
  }

  async function handleSubmit(
    event: SubmitEvent & {
      currentTarget: EventTarget & HTMLFormElement
      ageScreenCode?: string
    },
  ) {
    const rawData = Object.fromEntries(new FormData(event.currentTarget))
    const { ageScreenCode } = event
    const result = SignUpFormRefined.safeParse(rawData)
    if (result.success === false) {
      result.error.errors.forEach((error) => {
        const [path] = error.path
        new Field(path as string).setError($_(error.message))
      })
      return
    }
    const { data } = result
    if (
      !ageScreenCode &&
      region === 'US' &&
      Date.now() - new Date(data.birthday).getTime() <
        13 * 365 * 24 * 60 * 60 * 1000 // 13 years old
    ) {
      signUpSubmission = event
      return
    }
    if (
      region === 'CN' &&
      Date.now() - new Date(data.birthday).getTime() <
        14 * 365 * 24 * 60 * 60 * 1000 // 14 years old
    ) {
      showDialog(Dialog.AgeBlocking)
      return
    }

    isLoading = true

    const [responseType, responseData] = await signUp({
      convertToken,
      birthday: new Date(data.birthday).toISOString(),
      password: data.password,
      language: language.replace('_', '-'),
      username: data.username,
      ageScreenCode,
    })

    switch (responseType) {
      case 'USER_CREATED':
        dispatchWebViewAction({
          action: 'push',
          url: resolveURL('/email_validation', {
            user_id: responseData.id,
            from: '/signup',
          }).toString(),
          navBarTitle: $_('signup_title'),
          navBarVisible: true,
        })
        break
      case 'INVALID_AGE_SCREEN_CODE':
        new Field('code').setError($_('real_tree_plant_alert_error_422_title')) // 錯誤
        break
      case 'WRONG_TOKEN':
        showDialog(Dialog.WrongServer)
        break
      case 'NETWORK_ERROR':
        showDialog(Dialog.InternetDisconnected)
        break
      case 'UNHANDLED_HTTP_ERROR':
        showDialog(Dialog.UnhandledHTTPError(responseData))
        break
      case 'UNHANDLED_ERROR':
        showDialog(Dialog.UnhandledError(responseData.message))
        break
    }

    isLoading = false
  }

  onMount(() => {
    const { terms, privacy } = getTermsAndPrivacyUrlByAudience(audience)

    document.querySelector('span#terms')?.addEventListener('click', () => {
      dispatchWebViewAction({
        action: 'push',
        url: terms,
        navBarTitle: '',
        navBarVisible: true,
      })
    })
    document.querySelector('span#privacy')?.addEventListener('click', () => {
      dispatchWebViewAction({
        action: 'push',
        url: privacy,
        navBarTitle: '',
        navBarVisible: true,
      })
    })
  })
</script>

<form
  on:submit|preventDefault={handleSubmit}
  class="flex h-full flex-col"
  bind:this={form}
>
  <Main>
    <TextField
      name="username"
      placeholder={$_('placeholder_settings_name')}
      maxlength={30}
      schema={SignUpForm.shape.username}
      dataFieldName="username"
    />
    <TextField
      name="password"
      type="password"
      placeholder={$_('placeholder_signup_password')}
      note={$_('signup_password_format')}
      schema={SignUpForm.shape.password}
      dataFieldName="password"
    />
    <TextField
      name="confirm_password"
      type="password"
      placeholder={$_('placeholder_confirm_password')}
      schema={SignUpForm.shape.confirm_password}
      dataFieldName="password_confirm"
    />
    <DateInput
      name="birthday"
      placeholder={$_('placeholder_signup_birthday')}
      schema={SignUpForm.shape.birthday}
    />
  </Main>
  <Footer>
    <div class="grid grid-cols-[auto_1fr] items-start space-x-[8px]">
      <div class="relative h-4 w-4">
        <input
          type="checkbox"
          class={clsx(
            'absolute inset-0 appearance-none rounded-[4px] border-content-placeholder text-white',
            isAgreed ? 'bg-secondary' : 'border-[1.5px]',
          )}
          bind:checked={isAgreed}
          on:change={(event) => {
            const checked = event.currentTarget.checked
            logComponentEvent({
              type: 'checkbox',
              appAction: checked ? 'check' : 'uncheck',
              componentName: 'signup_policy',
            })
          }}
        />
        {#if isAgreed}
          <span
            class="pointer-events-none absolute inset-0 flex items-center justify-center"
          >
            <CheckMark />
          </span>
        {/if}
      </div>
      <p class="text-content-secondary text-label-md">
        {@html $_('onboarding_terms_privacy_policy', {
          values: {
            terms: `<span id="terms" class="underline text-secondary">${$_(
              'onboarding_terms',
            )}</span>`,
            privacy_policy: `<span id="privacy" class="underline text-secondary">${$_(
              'onboarding_privacy_policy',
            )}</span>`,
          },
        })}
      </p>
    </div>
    <button
      type="submit"
      class="btn"
      disabled={!isAgreed || isLoading || !$allTouched || $hasError}
      on:click={() => {
        logComponentEvent({
          type: 'btn',
          appAction: 'click',
          componentName: 'continue_signup',
        })
      }}
    >
      {#if isLoading}
        <Spinner />
      {/if}
      <span>
        {$_('btn_next')}
      </span>
    </button>
  </Footer>
</form>
<AgeScreenCodeDialog
  isOpen={!!signUpSubmission}
  onSubmit={handleAgeScreenCodeSubmit}
  onClose={handleAgeScreeningDialogClose}
  {isLoading}
/>
