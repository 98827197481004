<script lang="ts">
  import Dialog from '@/components/dialog.svelte'
  import Error from '@/components/icons/toast/error.svelte'
  import Success from '@/components/icons/toast/success.svelte'
  import Loader from '@/components/loader.svelte'
  import { setNavigationBar } from '@/lib/native-messaging/set-navigation-bar'
  import { _ } from 'svelte-i18n'
  import { Toaster } from 'svelte-sonner'

  const { pathname } = new URL(location.href)
  switch (pathname) {
    case '/signup_or_signin':
      setNavigationBar({ title: $_('signup_or_signin_title'), visible: true })
      break
    case '/signin':
      setNavigationBar({ title: $_('signin_title'), visible: true })
      break
    case '/signup':
      setNavigationBar({ title: $_('signup_title'), visible: true })
      break
    case '/email_validation':
      setNavigationBar({ title: $_('email_verification_title'), visible: true })
      break
    case '/dashboard':
      setNavigationBar({ title: $_('manage_account_title'), visible: true })
      break
    case '/':
      setNavigationBar({ title: $_('manage_account_title'), visible: true })
      break
    case '/change_email':
      setNavigationBar({ title: $_('change_email_title'), visible: true })
      break
    case '/change_password':
      setNavigationBar({ title: $_('change_password_title'), visible: true })
      break
    case '/delete_account':
      setNavigationBar({ title: $_('delete_account_title'), visible: true })
      break
    case '/reset_password':
      setNavigationBar({ title: $_('reset_password_title'), visible: true })
      break
    case '/entry':
      setNavigationBar({ title: '', visible: false })
      break
  }
</script>

<slot />
<Dialog />
<Toaster
  position="bottom-center"
  toastOptions={{
    class:
      'rounded-[16px] px-5 py-3 gap-3 bg-element-toast text-white text-xs [&>[data-icon]]:m-0 [&>[data-icon]]:w-6 [&>[data-icon]]:h-6 [&_div[data-title]]:font-normal border-none',
  }}
>
  <Success slot="success-icon" />
  <Error slot="error-icon" />
</Toaster>
<Loader />
