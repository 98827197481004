<script lang="ts">
  import { logComponentEvent } from '@/lib/native-messaging/log-event'
  import { flyAndScale } from '@/lib/utils/transitions'
  import { isGlobalLoaderVisible } from '@/stores'
  import { Select, type Selected } from 'bits-ui'
  import clsx from 'clsx'
  import { _ } from 'svelte-i18n'
  import DownArrow from './icons/down-arrow.svelte'
  import Globe from './icons/globe.svelte'

  const isStaging = window.location.host.includes('staging')

  type Server = 'global' | 'china' | 'staging'

  const urlByServer = {
    global: 'https://auth.seekrtech.com/entry',
    china: 'https://auth.upwardsware.com/entry',
    staging: 'https://auth.staging.seekrtech.com/entry',
  } satisfies Record<Server, string>

  const isChina = window.location.host.includes('upwardsware')

  const optionByServer = {
    global: { label: $_('global_server'), value: 'global' },
    china: { label: $_('china_server'), value: 'china' },
    ...(isStaging && {
      staging: { label: 'Staging server', value: 'staging' },
    }),
  } as Record<Server, Selected<Server>>

  const items = Object.values(optionByServer)

  let selected = isStaging
    ? optionByServer.staging
    : isChina
      ? optionByServer.china
      : optionByServer.global

  function handleSelectedChange(selected?: Selected<Server>) {
    if (!selected) return
    $isGlobalLoaderVisible = true
    logComponentEvent({
      appAction: 'select',
      type: 'selector',
      componentName: 'server_select',
      componentValue: `${selected.value}_server`,
    })
    // TODO: Refactor to a native messaging function
    window.postMessage({
      type: 'change_server_region',
      payload: {
        region: selected.value,
      },
    })
    window.location.href = urlByServer[selected.value] + location.search
  }
</script>

<Select.Root {items} bind:selected onSelectedChange={handleSelectedChange}>
  <Select.Trigger
    class="mt-[10px] flex h-7 items-center rounded-2xl bg-[#f4f4f4] px-2 text-[14px] text-[#47474799]"
    disabled={isStaging}
  >
    <span class="mr-1 size-4">
      <Globe />
    </span>
    <Select.Value />
    <span class="ml-2 size-4">
      <DownArrow />
    </span>
  </Select.Trigger>

  <Select.Content
    transition={flyAndScale}
    class="w-64 rounded-[14px] bg-white p-4 shadow-[0_4px_18px_0_#00000026]"
    sameWidth={false}
    align="start"
    sideOffset={12}
  >
    <p class="mb-4 text-[14px] text-content-secondary">
      {$_('login_choose_server')}
    </p>

    {#each items as item, index}
      <Select.Item value={item.value} label={item.label} let:isSelected>
        <span class={clsx('text-[#333333]', isSelected && 'text-primary')}
          >{item.label}</span
        >
        <Select.ItemIndicator />
      </Select.Item>
      {#if index < items.length - 1}
        <div class="my-[14px] h-[1px] bg-[#0000000D]" />
      {/if}
    {/each}

    <Select.Arrow />
  </Select.Content>
</Select.Root>
