<script lang="ts">
  import ForwardIcon from '@/components/icons/forward.svelte'
  import { logComponentEvent } from '@/lib/native-messaging/log-event'
  import { dispatchWebViewAction } from '@/lib/native-messaging/web-view-action'
  import { resolveURL } from '@/lib/utils/url'
  import clsx from 'clsx'
  import { _ } from 'svelte-i18n'

  export let titleKey: string
  export let pathname: string = ''
  export let isDanger: boolean = false
  export let action: undefined | (() => void) = undefined
  export let componentName: string
  export let disabled: boolean = false

  function handleClick() {
    logComponentEvent({
      type: 'cell',
      appAction: 'click',
      componentName,
    })
    if (pathname) {
      dispatchWebViewAction({
        action: 'modal',
        url: resolveURL(pathname).toString(),
        navBarTitle: $_(titleKey),
        navBarVisible: true,
      })
    }
    action?.()
  }
</script>

<button
  class="relative p-4 disabled:opacity-40"
  on:click={handleClick}
  {disabled}
>
  <div
    class={clsx(
      'flex items-center justify-between text-body-md',
      isDanger ? 'text-warning' : 'text-content-primary',
    )}
  >
    <span>
      {$_(titleKey)}
    </span>
    <ForwardIcon />
  </div>
  <!-- divider -->
  <div class="absolute bottom-0 left-4 right-4 mx-auto h-[.5px] bg-divider" />
</button>
