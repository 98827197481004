<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M17.7448 8.59352C18.0967 8.96239 18.083 9.54669 17.7141 9.89859L10.941 16.3601C10.5842 16.7006 10.0227 16.7003 9.66611 16.3596L6.28534 13.1288C5.91677 12.7766 5.90351 12.1923 6.25573 11.8237C6.60794 11.4552 7.19225 11.4419 7.56082 11.7941L10.3044 14.416L16.4398 8.5628C16.8086 8.2109 17.3929 8.22466 17.7448 8.59352Z"
    fill="#8DC925"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12 1.84615C6.39219 1.84615 1.84615 6.39219 1.84615 12C1.84615 17.6078 6.39219 22.1538 12 22.1538C17.6078 22.1538 22.1538 17.6078 22.1538 12C22.1538 6.39219 17.6078 1.84615 12 1.84615ZM0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
    fill="#8DC925"
  />
</svg>
