<svg
  width="16"
  height="16"
  viewBox="0 0 16 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <mask
    id="mask0_20911_7477"
    style="mask-type:alpha"
    maskUnits="userSpaceOnUse"
    x="0"
    y="0"
    width="16"
    height="16"
  >
    <rect width="16" height="16" fill="url(#pattern0)" />
  </mask>
  <g mask="url(#mask0_20911_7477)">
    <rect x="-4" y="-4" width="24" height="24" fill="#565859" />
  </g>
  <defs>
    <pattern
      id="pattern0"
      patternContentUnits="objectBoundingBox"
      width="1"
      height="1"
    >
      <use xlink:href="#image0_20911_7477" transform="scale(0.0104167)" />
    </pattern>
    <image
      id="image0_20911_7477"
      width="96"
      height="96"
      xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAACxKAAAsSgF3enRNAAACMklEQVR4nO3cwU0CYRRF4YNx79I2tALowFYoYUqgFTuACrQNl1SAC8eEAA5CxPvefe8sgcWf+wUlYZjZbrej03WnPkD1GkBcA4hrAHENIK4BxDWAuAYQ1wDiGkBcA4hrAHENIK4BxDWAuHv1AQAe34YnYAXMx4c2wPLjeXjXnep/mqm/kBnHXwMPB09tgYU7QoQ/QQPH4zM+th6BbIsA8DLxnD1CBIBzWSNEAHj9xWtsESIADHz9wz2XJYIcYPyUs6AoghwAaiOEAIC6CGEAoCZCKACohxAOAGohhASAOghhAaAGQmgA8EcIDwDeCCkAwBchDQB4IqQCAD+EdADghZASAHwQ0gKAB0JqAMiPkB4AciNYAEBeBBsAyIlgBQD5EOwAIBeCJQDkQbAFgKsQVjc90ImsAeBihPn5l/xt9gDRsweY+P3BqTa3Pc1x1gAXjr8Fljc90IlsAa4YX/JrHEuALOODIUCm8cEMINv4YASQcXwwAcg6PhgAZB4fkgNkHx8SAziMD0kBXMaHhABO40MyALfxIRGA4/iQBMB1fEgA4Dw+BAdwHx8CA1QYH4ICVBkfAgJUGh+CAVQbHwIBVBwfggBUHR8CAFQeHwIA8PN9Qw+zGx9iAEzdN/Q7y/EhBsC5bMeHGABT9w21Hh9iAAycvnTcfnwIALB3/f7+lckbCowPAW5fXz35O6B6DSCuAcQ1gLgGENcA4hpAXAOIawBxDSCuAcQ1gLgGENcA4hpA3CfA8GOcQQCULQAAAABJRU5ErkJggg=="
    />
  </defs>
</svg>
