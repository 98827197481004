type Payload = {
  title: string
  visible: boolean
}

export function setNavigationBar(payload: Payload) {
  const message = {
    type: 'set_navigation_bar',
    payload,
  }
  window.postMessage(message)
}
