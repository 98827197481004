<script lang="ts">
  import { Rive } from '@rive-app/canvas'
  import { onMount } from 'svelte'
  export let w: number
  export let h: number
  export let src: string
  export let autoplay = false

  let canvas: HTMLCanvasElement

  onMount(() => {
    new Rive({
      canvas,
      autoplay,
      src,
    })
  })
</script>

<canvas bind:this={canvas} width={w} height={h} />
