<svg
  width="29"
  height="29"
  viewBox="0 0 29 29"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M7.7 21C7.2325 21 6.83229 20.8409 6.49938 20.5227C6.16646 20.2044 6 19.8219 6 19.375V9.625C6 9.17812 6.16646 8.79557 6.49938 8.47734C6.83229 8.15911 7.2325 8 7.7 8H21.3C21.7675 8 22.1677 8.15911 22.5006 8.47734C22.8335 8.79557 23 9.17812 23 9.625V19.375C23 19.8219 22.8335 20.2044 22.5006 20.5227C22.1677 20.8409 21.7675 21 21.3 21H7.7ZM14.5 15.3125L21.3 11.25V9.625L14.5 13.6875L7.7 9.625V11.25L14.5 15.3125Z"
    fill="#51A387"
  />
</svg>
