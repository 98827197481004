export function scriptLoad() {
  let onLoad: () => void
  let onError: () => void
  const loading = new Promise<void>((resolve, reject) => {
    onLoad = resolve
    onError = reject
  })
  return {
    loading,
    get onLoad() {
      return onLoad
    },
    get onError() {
      return onError
    },
  }
}
