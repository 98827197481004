import { t } from '@/lib/translate'
import type { Message } from '@/types/message'

type ShowToastMessage = Extract<Message, { type: 'show_toast' }>

type Payload = ShowToastMessage['payload']

export function showToast(payload: Payload) {
  const message: ShowToastMessage = {
    type: 'show_toast',
    payload,
  }
  window.postMessage(message)
}

export const Toast = {
  get ChangeEmailSuccess() {
    return {
      type: 'success',
      messageKey: t('toast_manage_account_change_email_success'),
    } satisfies Payload
  },
  get ChangePasswordSuccess() {
    return {
      type: 'success',
      messageKey: t('toast_manage_account_change_password_success'),
    } satisfies Payload
  },
  get ResetPasswordSuccess() {
    return {
      type: 'success',
      messageKey: t('toast_reset_password_success'),
    } satisfies Payload
  },
  get UsernameRequired() {
    return {
      type: 'error',
      messageKey: t('toast_user_name_empty'),
    } satisfies Payload
  },
  get UsernameTooLong() {
    return {
      type: 'error',
      messageKey: t('toast_text_max'),
    } satisfies Payload
  },
}
