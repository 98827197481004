import type { FormStatus } from '@/types/form'
import { getContext, setContext } from 'svelte'
import { derived, writable, type Writable } from 'svelte/store'

export const defaultFormStoreKey = 'formStore'

export function createFormStatusContext(key?: string) {
  const formStatus = writable<FormStatus>({})
  setContext(key || defaultFormStoreKey, formStatus)

  return formStatus
}

export function getFormStatus(params?: {
  key?: string
  formStatus?: Writable<FormStatus>
}) {
  const formStatus =
    params?.formStatus ||
    getContext<Writable<FormStatus> | undefined>(
      params?.key || defaultFormStoreKey,
    )
  if (!formStatus) {
    throw new Error(
      '`getFormStatus` must be used within a `createFormStatus` context',
    )
  }
  const allTouched = derived(formStatus, ($formStore) =>
    Object.values($formStore).every((status) => status.touched),
  )
  const hasError = derived(formStatus, ($formStore) =>
    Object.values($formStore).some((status) => status.error),
  )

  class Field {
    constructor(private fieldName: string) {}

    setError(error: string) {
      formStatus!.update((status) => {
        return {
          ...status,
          [this.fieldName]: {
            touched: true,
            error,
          },
        }
      })
    }

    clearError() {
      formStatus!.update((status) => {
        return {
          ...status,
          [this.fieldName]: {
            ...status[this.fieldName],
            error: '',
          },
        }
      })
    }

    init() {
      formStatus!.update((status) => {
        return {
          ...status,
          [this.fieldName]: {
            touched: false,
            error: '',
          },
        }
      })
    }

    touch() {
      formStatus!.update((status) => {
        return {
          ...status,
          [this.fieldName]: {
            touched: true,
            error: '',
          },
        }
      })
    }

    remove() {
      formStatus!.update((status) => {
        const { [this.fieldName]: _, ...rest } = status
        return rest
      })
    }
  }

  return {
    formStatus,
    allTouched,
    hasError,
    Field,
  }
}
