export function resolveURL(
  pathname = '/',
  searchParams: Record<string, string> = {},
) {
  const url = new URL(location.href)
  url.pathname = pathname
  Object.entries(searchParams).forEach(([key, val]) => {
    url.searchParams.set(key, val)
  })
  return url
}
