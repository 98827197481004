import type { Message } from '@/types/message'

type LogEventMessage = Extract<Message, { type: 'log_event' }>

export function logGeneralEvent(
  eventName: string,
  params: Record<string, string>,
) {
  const message: LogEventMessage = {
    type: 'log_event',
    payload: {
      eventName,
      eventParams: params,
    },
  }
  window.postMessage(message)
}

export function logComponentEvent(params: {
  type: 'btn' | 'cell' | 'text' | 'checkbox' | 'time' | 'selector'
  appAction: 'click' | 'check' | 'uncheck' | 'show' | 'select'
  componentName: string
  componentValue?: string
}) {
  const message: LogEventMessage = {
    type: 'log_event',
    payload: {
      eventName: 'component_event',
      eventParams: {
        type: params.type,
        app_action: params.appAction,
        component_name: params.componentName,
        ...(params.componentValue && {
          component_value: params.componentValue,
        }),
      },
    },
  }
  window.postMessage(message)
}

export function logTextEditEvent(action: 'start' | 'end', name: string) {
  const message: LogEventMessage = {
    type: 'log_event',
    payload: {
      eventName: `text_edit_${action}`,
      eventParams: {
        field_name: name,
      },
    },
  }
  window.postMessage(message)
}

export function logDatePickedEvent(date: string) {
  const message: LogEventMessage = {
    type: 'log_event',
    payload: {
      eventName: 'birth_year_month',
      eventParams: {
        year_month: date,
      },
    },
  }
  window.postMessage(message)
}
