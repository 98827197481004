export const params = new URLSearchParams(window.location.search)

export const isStandalone = params.get('variant') === 'standalone'

export const version = params.get('version')

export const platform = params.get('platform')

export const convertToken = params.get('convert_token')

export const audience = params.get('audience') as
  | null
  | 'seekrtech.forest'
  | 'seekrtech.sleeptown'
  | 'seekrtech.waterdo'
  | 'seekrtech.receipt'
  | 'upwardsware.forest'
  | 'upwardsware.waterdo'
  | 'upwardsware.receipt'
  | 'seekrtech.forest.next'
  | 'seekrtech.daily'

export const redirectUri = params.get('redirect_uri') ?? ''
