<script lang="ts">
  import Spinner from '@/components/anim/spinner.svelte'
  import ForgotPasswordButton from '@/components/forgot-password-button.svelte'
  import TextField from '@/components/text-field.svelte'
  import Footer from '@/layouts/footer.svelte'
  import Main from '@/layouts/main.svelte'
  import { Dialog, showDialog } from '@/lib/native-messaging/dialog'
  import { logComponentEvent } from '@/lib/native-messaging/log-event'
  import { dispatchWebViewAction } from '@/lib/native-messaging/web-view-action'
  import { HTTPError } from '@/lib/utils/error'
  import { resolveURL } from '@/lib/utils/url'
  import { ChangeEmailForm } from '@/schema'
  import type { FormStatus } from '@/types/form'
  import { setContext } from 'svelte'
  import { _ } from 'svelte-i18n'
  import { writable } from 'svelte/store'

  export let user = {
    id: '',
    email: '',
  }

  let isLoading = false

  const formStore = writable<FormStatus>({})
  setContext('formStore', formStore)

  $: allTouched = Object.values($formStore).every((status) => status.touched)
  $: hasError = Object.values($formStore).some((status) => !!status.error)

  async function handleSubmit(
    event: SubmitEvent & {
      currentTarget: EventTarget & HTMLFormElement
    },
  ) {
    const data = {
      password: event.currentTarget.password.value,
      newEmail: event.currentTarget.newEmail.value,
    }
    const result = ChangeEmailForm.safeParse(data)
    if (result.success === false) {
      result.error.errors.forEach((error) => {
        const [path] = error.path
        formStore.update((status) => {
          return {
            ...status,
            [path]: {
              touched: true,
              error: $_(error.message),
            },
          }
        })
      })
      return
    }
    isLoading = true
    try {
      const res = await fetch(resolveURL('/change_email'), {
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          email: data.newEmail,
          password: data.password,
        }),
      })
      if (!res.ok) {
        throw new HTTPError(res.status, res.statusText)
      }
      dispatchWebViewAction({
        action: 'push',
        url: resolveURL('/email_validation', {
          user_id: user.id,
          from: '/change_email',
          email: data.newEmail,
        }).toString(),
        navBarTitle: $_('email_verification_title'),
        navBarVisible: true,
      })
    } catch (err) {
      if (err instanceof TypeError) {
        showDialog(Dialog.InternetDisconnected)
        return
      }
      if (err instanceof HTTPError) {
        switch (err.status) {
          case 403:
            showDialog(Dialog.WrongPassword)
            return
          case 409:
            showDialog(Dialog.EmailUsed)
            return
          default:
            showDialog({
              type: 'default',
              name: 'unhandled_http_error',
              titleKey: 'Unhandled HTTP Error',
              contentKey: `status: ${err.status}`,
              primaryBtnKey: 'OK',
            })
            return
        }
      }
    } finally {
      isLoading = false
    }
  }
</script>

<form on:submit|preventDefault={handleSubmit} class="flex h-full flex-col">
  <Main>
    <TextField
      type="password"
      name="password"
      placeholder={$_('placeholder_change_email_password')}
      schema={ChangeEmailForm.shape.password}
      dataFieldName="password"
    />
    <TextField
      type="email"
      name="newEmail"
      placeholder={$_('placeholder_change_email_new_email')}
      schema={ChangeEmailForm.shape.newEmail}
      dataFieldName="new_email"
    />
    <div class="text-right text-body-md">
      <ForgotPasswordButton email={user.email} />
    </div>
  </Main>
  <Footer>
    <button
      class="btn"
      disabled={isLoading || !allTouched || hasError}
      on:click={() => {
        logComponentEvent({
          type: 'btn',
          appAction: 'click',
          componentName: 'continue_email_edit',
        })
      }}
    >
      {#if isLoading}
        <Spinner />
      {/if}
      <span>{$_('btn_next')}</span>
    </button>
  </Footer>
</form>
