<script lang="ts">
  import EyeCloseIcon from '@/components/icons/eye-close.svelte'
  import EyeIcon from '@/components/icons/eye.svelte'
  import { getFormStatus } from '@/context/form-status'
  import {
    logComponentEvent,
    logTextEditEvent,
  } from '@/lib/native-messaging/log-event'
  import { Toast, showToast } from '@/lib/native-messaging/toast'
  import { clsx } from 'clsx'
  import { onDestroy, onMount } from 'svelte'
  import { _ } from 'svelte-i18n'
  import type { HTMLInputAttributes } from 'svelte/elements'
  import type { ZodTypeAny } from 'zod'

  const TEXT_INPUT_TYPES = ['text', 'email', 'password']

  interface $$Props extends HTMLInputAttributes {
    name: string
    type?: HTMLInputAttributes['type']
    maxlength?: number
    note?: string
    schema?: ZodTypeAny | null
    dataFieldName?: string
  }

  export let name: string
  export let type: HTMLInputAttributes['type'] = 'text'
  export let maxlength: number | null = null
  export let note = ''
  export let schema: ZodTypeAny | null = null
  export let dataFieldName = ''

  const { formStatus, Field } = getFormStatus()

  const field = new Field(name)

  onMount(() => {
    field.init()
  })
  onDestroy(() => {
    field.remove()
  })

  let inputVal = ''
  let isFocused = false
  let inputElement: HTMLInputElement
  let isPasswordVisible = type !== 'password'
  $: resolvedType =
    type === 'password' ? (isPasswordVisible ? 'text' : 'password') : type

  function validateInputValue() {
    if (!schema) return
    const result = schema.safeParse(inputVal)
    if (result.success) {
      field.clearError()
    } else {
      result.error.errors.forEach((err) => {
        field.setError($_(err.message))
      })
    }
  }

  function handleEyeBtnClick() {
    logComponentEvent({
      type: 'btn',
      appAction: 'click',
      componentName: isPasswordVisible ? 'hide_password' : 'show_password',
    })
    isPasswordVisible = !isPasswordVisible
    inputElement.focus()
  }

  function handleFocus() {
    if (TEXT_INPUT_TYPES.includes(type as string)) {
      logTextEditEvent('start', dataFieldName)
    }
    isFocused = true
    field.touch()
  }

  function handleBlur() {
    if (TEXT_INPUT_TYPES.includes(type as string)) {
      logTextEditEvent('end', dataFieldName)
    }
    isFocused = false
    validateInputValue()
  }

  function handleInput(
    event: Event & {
      currentTarget: EventTarget & HTMLInputElement
    },
  ) {
    if (maxlength !== null) {
      if (event.currentTarget.value.length > maxlength) {
        showToast(Toast.UsernameTooLong)
      }
      inputVal = event.currentTarget.value.slice(0, maxlength)
      event.currentTarget.value = inputVal
    } else {
      inputVal = event.currentTarget.value
    }
  }
</script>

<div class="relative">
  <input
    {...$$restProps}
    data-1p-ignore
    bind:this={inputElement}
    class={clsx(
      'h-[45px] w-full appearance-none rounded-[16px] bg-element-input px-[12px] text-left text-content-primary outline-[1px] outline-offset-[-1px] text-body-md placeholder:text-content-placeholder',
      $formStatus[name]?.error
        ? 'outline outline-warning'
        : 'outline-secondary focus:outline',
    )}
    {name}
    type={resolvedType}
    value={inputVal}
    on:input={handleInput}
    on:focus={handleFocus}
    on:blur={handleBlur}
  />
  {#if type === 'password'}
    <button
      type="button"
      class="absolute bottom-0 right-2 top-[12.5px] h-5 w-5"
      on:click={handleEyeBtnClick}
    >
      {#if isPasswordVisible}
        <EyeCloseIcon />
      {:else}
        <EyeIcon />
      {/if}
    </button>
  {/if}
  {#if $formStatus[name]?.error}
    <p class="mt-[8px] text-right text-warning text-label-md">
      {$formStatus[name]?.error}
    </p>
  {/if}
  {#if maxlength !== null && isFocused}
    <p class="mt-[8px] text-right text-content-tertiary text-label-md">
      {inputVal.length}/{maxlength}
    </p>
  {/if}
  {#if note && isFocused}
    <p class="mt-[8px] text-right text-content-tertiary text-label-md">
      {note}
    </p>
  {/if}
</div>
