<script lang="ts">
  import { cn } from '@/lib/utils/cn'
  import { Button } from 'bits-ui'

  type $$Props = Button.Props & {}

  let className: $$Props['class'] = undefined

  export { className as class }
</script>

<Button.Root
  class={cn('underline text-title-md', className)}
  {...$$restProps}
  on:click
  on:keydown
>
  <slot />
</Button.Root>
