<script lang="ts">
  import Footer from '@/layouts/footer.svelte'
  import Main from '@/layouts/main.svelte'

  async function post() {
    const res = await fetch('/test-server-redirect', {
      method: 'POST',
      redirect: 'follow',
    })
    if (res.redirected) {
      window.location.href = res.url
    }
  }
</script>

<Main>
  <button class="btn" on:click={post}>my button</button>
</Main>
<Footer />
