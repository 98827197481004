import type { Message } from '@/types/message'

type LoadingViewActionMessage = Extract<
  Message,
  { type: 'loading_view_action' }
>

export function showLoadingView() {
  const message: LoadingViewActionMessage = {
    type: 'loading_view_action',
    payload: {
      action: 'show',
    },
  }
  window.postMessage(message)
}

export function dismissLoadingView() {
  const message: LoadingViewActionMessage = {
    type: 'loading_view_action',
    payload: {
      action: 'dismiss',
    },
  }
  window.postMessage(message)
}
