<svg
  width="29"
  height="29"
  viewBox="0 0 29 29"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M14.2672 8.07692C15.2656 8.07692 16.5172 7.40559 17.2625 6.51049C17.9375 5.6993 18.4297 4.56643 18.4297 3.43357C18.4297 3.27972 18.4156 3.12587 18.3875 3C17.2766 3.04196 15.9406 3.74126 15.1391 4.67832C14.5063 5.39161 13.9297 6.51049 13.9297 7.65734C13.9297 7.82518 13.9578 7.99301 13.9719 8.04895C14.0422 8.06294 14.1547 8.07692 14.2672 8.07692ZM10.7516 25C12.1156 25 12.7203 24.0909 14.4219 24.0909C16.1516 24.0909 16.5312 24.972 18.05 24.972C19.5406 24.972 20.5391 23.6014 21.4813 22.2587C22.5359 20.7203 22.9719 19.2098 23 19.1399C22.9016 19.1119 20.0469 17.951 20.0469 14.6923C20.0469 11.8671 22.2969 10.5944 22.4234 10.4965C20.9328 8.37063 18.6687 8.31469 18.05 8.31469C16.3766 8.31469 15.0125 9.32168 14.1547 9.32168C13.2266 9.32168 12.0031 8.37063 10.5547 8.37063C7.79844 8.37063 5 10.6364 5 14.9161C5 17.5734 6.04062 20.3846 7.32031 22.2028C8.41719 23.7413 9.37344 25 10.7516 25Z"
    fill="black"
  />
</svg>
