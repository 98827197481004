<script lang="ts">
  import { cn } from '@/lib/utils/cn'
  import { Button } from 'bits-ui'

  type $$Props = Button.Props & {}

  let className: $$Props['class'] = undefined

  export { className as class }
</script>

<Button.Root
  class={cn(
    'flex h-[45px] items-center gap-2 rounded-[30px] border border-border px-3 text-content-primary transition-transform duration-200 ease-out text-body-lg active:scale-[.97] disabled:opacity-40',
    className,
  )}
  {...$$restProps}
  on:click
  on:keydown
>
  <div class="max-w-[29px]"><slot name="icon" /></div>
  <slot name="text" />
</Button.Root>
