import { z } from 'zod'

export type Message =
  | {
      type: 'show_dialog'
      payload: {
        type: 'default' | 'warning'
        name: string
        imageName?: string
        titleKey?: string
        contentKey?: string
        primaryBtnKey: string
        secondaryBtnKey?: string
        primaryBtnAction?: string
        secondaryBtnAction?: string
      }
    }
  | {
      type: 'dialog_action'
      payload: {
        action: string
      }
    }
  | {
      type: 'show_toast'
      payload: {
        type: 'info' | 'success' | 'error'
        messageKey: string
        duration?: number
      }
    }
  | {
      type: 'loading_view_action'
      payload: {
        action: 'show' | 'dismiss'
      }
    }
  | {
      type: 'account_action'
      payload:
        | {
            action:
              | 'sign_out'
              | 'delete'
              | 'change_password'
              | 'reset_password'
              | 'guest_login_as_existed_user'
              | 'guest_complete_signup'
          }
        | {
            action: 'change_username'
            updated: {
              username: string
            }
          }
        | {
            action: 'change_email'
            updated: {
              email: string
            }
          }
    }
  | {
      type: 'web_view_action'
      payload:
        | {
            action: 'pop' | 'dismiss' | 'pop_to_root' | 'end_session'
          }
        | {
            action: 'push' | 'modal'
            url: string
          }
    }
  | {
      type: 'log_event'
      payload: {
        eventName: string
        eventParams?: Record<string, string>
      }
    }
  | {
      type: 'sign_in_with_legacy'
      payload: {
        email: string
      }
    }

export type ThirdPartySignOnMessage = {
  type: 'third_party_sign_on'
  payload: {
    provider: 'google' | 'apple'
  }
}

export const IdTokenMessage = z.object({
  type: z.literal('id_token'),
  payload: z.object({
    token: z.literal('null').or(z.string()),
  }),
})
