<script lang="ts">
  import { logComponentEvent } from '@/lib/native-messaging/log-event'
  import { dispatchWebViewAction } from '@/lib/native-messaging/web-view-action'
  import { resolveURL } from '@/lib/utils/url'
  import { _ } from 'svelte-i18n'

  export let email = ''
</script>

<button
  type="button"
  class="text-secondary underline"
  on:click={() => {
    logComponentEvent({
      type: 'text',
      appAction: 'click',
      componentName: 'forget_password',
    })
    dispatchWebViewAction({
      action: 'modal',
      url: resolveURL('/reset_password', {
        from: location.pathname,
        ...(email && { email }),
      }).toString(),
      navBarTitle: $_('reset_password_title'),
      navBarVisible: true,
    })
  }}
>
  {$_('forgot_password')}
</button>
