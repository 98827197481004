<svg id="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <defs>
    <style>
      .cls-1 {
        fill: none;
        stroke: currentColor;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 1.5px;
      }
    </style>
  </defs>
  <path
    class="cls-1"
    d="M5.45,15.19l-.33,2.79c0,.51,.43,.93,.93,.92l2.74-.37c.42,0,.82-.17,1.12-.47l9.5-9.5c.64-.64,.62-1.7-.04-2.36l-1.6-1.6c-.66-.66-1.72-.68-2.36-.04L5.92,14.05c-.3,.3-.47,.71-.47,1.14Z"
  />
  <line class="cls-1" x1="18.9" y1="18.9" x2="12.75" y2="18.9" />
  <line class="cls-1" x1="14.42" y1="5.55" x2="18.59" y2="9.39" />
</svg>
