<script lang="ts">
  import Spinner from '@/components/anim/spinner.svelte'
  import ForgotPasswordButton from '@/components/forgot-password-button.svelte'
  import TextField from '@/components/text-field.svelte'
  import { createFormStatusContext, getFormStatus } from '@/context/form-status'
  import Footer from '@/layouts/footer.svelte'
  import Main from '@/layouts/main.svelte'
  import { changePassword } from '@/lib/api'
  import { sendAccountActionMessage } from '@/lib/native-messaging/account-action'
  import { Dialog, showDialog } from '@/lib/native-messaging/dialog'
  import { logComponentEvent } from '@/lib/native-messaging/log-event'
  import { Toast, showToast } from '@/lib/native-messaging/toast'
  import { dispatchWebViewAction } from '@/lib/native-messaging/web-view-action'
  import { ChangePasswordForm, ChangePasswordFormRefined } from '@/schema'
  import { _ } from 'svelte-i18n'

  export let user = {
    email: '',
  }

  const formStore = createFormStatusContext()
  const { allTouched, hasError } = getFormStatus({
    formStatus: formStore,
  })

  let isLoading = false
  async function handleSubmit(
    event: SubmitEvent & {
      currentTarget: EventTarget & HTMLFormElement
    },
  ) {
    const rawData = Object.fromEntries(new FormData(event.currentTarget))
    const parseResult = ChangePasswordFormRefined.safeParse(rawData)
    if (parseResult.success === false) {
      parseResult.error.errors.forEach((error) => {
        const [path] = error.path
        formStore.update((status) => ({
          ...status,
          [path]: { touched: true, error: $_(error.message) },
        }))
      })
      return
    }
    isLoading = true
    const [result, payload] = await changePassword({
      oldPassword: parseResult.data.originalPassword,
      newPassword: parseResult.data.newPassword,
    })
    isLoading = false
    switch (result) {
      case 'SUCCESS':
        sendAccountActionMessage({ action: 'change_password' })
        showToast(Toast.ChangePasswordSuccess)
        dispatchWebViewAction({ action: 'dismiss' })
        break
      case 'WRONG_PASSWORD':
        showDialog(Dialog.WrongOriginalPassword)
        break
      case 'NETWORK_ERROR':
        showDialog(Dialog.InternetDisconnected)
        break
      case 'UNHANDLED_HTTP_ERROR':
        showDialog(Dialog.UnhandledHTTPError(payload))
        break
      case 'UNHANDLED_ERROR':
        showDialog(Dialog.UnhandledError(payload.message))
        break
    }
  }
</script>

<form class="flex h-full flex-col" on:submit|preventDefault={handleSubmit}>
  <Main>
    <TextField
      type="password"
      name="originalPassword"
      placeholder={$_('placeholder_change_password_original_password')}
      schema={ChangePasswordForm.shape.originalPassword}
      dataFieldName="old_password"
    />
    <TextField
      type="password"
      name="newPassword"
      placeholder={$_('placeholder_change_password_new_password')}
      schema={ChangePasswordForm.shape.newPassword}
      dataFieldName="new_password"
    />
    <TextField
      type="password"
      name="confirmNewPassword"
      placeholder={$_('placeholder_change_password_confirm_new_password')}
      schema={ChangePasswordForm.shape.confirmNewPassword}
      dataFieldName="new_password_confirm"
    />
    <div class="text-right text-body-md">
      <ForgotPasswordButton email={user.email} />
    </div>
  </Main>
  <Footer>
    <button
      class="btn"
      disabled={isLoading || !$allTouched || $hasError}
      on:click={() => {
        logComponentEvent({
          type: 'btn',
          appAction: 'click',
          componentName: 'password_edit',
        })
      }}
    >
      {#if isLoading}
        <Spinner />
      {/if}
      <span>{$_('btn_change_password')}</span>
    </button>
  </Footer>
</form>
