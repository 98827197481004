<script lang="ts">
  import Spinner from '@/components/anim/spinner.svelte'
  import ForgotPasswordButton from '@/components/forgot-password-button.svelte'
  import TextField from '@/components/text-field.svelte'
  import Footer from '@/layouts/footer.svelte'
  import Main from '@/layouts/main.svelte'
  import { grantAccess, signIn } from '@/lib/api'
  import { sendAccountActionMessage } from '@/lib/native-messaging/account-action'
  import { Dialog, showDialog } from '@/lib/native-messaging/dialog'
  import {
    logComponentEvent,
    logGeneralEvent,
  } from '@/lib/native-messaging/log-event'
  import { dispatchWebViewAction } from '@/lib/native-messaging/web-view-action'
  import { redirect } from '@/lib/oauth/redirect'
  import { resolveURL } from '@/lib/utils/url'
  import { redirectUri } from '@/params'
  import { SignInForm } from '@/schema'
  import type { FormStatus } from '@/types/form'
  import { setContext } from 'svelte'
  import { _ } from 'svelte-i18n'
  import { writable } from 'svelte/store'

  const params = new URLSearchParams(location.search)
  const isGuest = !!params.get('convert_token')

  const formStore = writable<FormStatus>({})
  setContext('formStore', formStore)

  $: allTouched = Object.values($formStore).every((status) => status.touched)
  $: hasError = Object.values($formStore).some((status) => !!status.error)

  let isLoading = false

  async function submitGrantingAccess() {
    isLoading = true
    const [result, payload] = await grantAccess()
    isLoading = false
    if (result !== 'SUCCESS') {
      showDialog(Dialog.RetryDialog, (action) => {
        if (action === Dialog.RetryDialog.primaryBtnAction) {
          submitGrantingAccess()
        }
      })
      return
    }
    redirect(redirectUri, { ...Object.fromEntries(params), ...payload })
    if (isGuest) {
      sendAccountActionMessage({ action: 'guest_login_as_existed_user' })
    }
    dispatchWebViewAction({ action: 'end_session' })
  }

  async function handleSubmit(
    event: SubmitEvent & {
      currentTarget: EventTarget & HTMLFormElement
    },
  ) {
    const parsed = SignInForm.safeParse(
      Object.fromEntries(new FormData(event.currentTarget)),
    )
    if (parsed.success === false) {
      parsed.error.errors.forEach((error) => {
        const [target] = error.path
        formStore.update((status) => {
          return {
            ...status,
            [target]: {
              touched: true,
              error: $_(error.message),
            },
          }
        })
      })
      return
    }

    isLoading = true
    const [result, payload] = await signIn(parsed.data.password)
    isLoading = false
    switch (result) {
      case 'USER_SIGNED_IN':
        logGeneralEvent('seekrauth_login', {
          is_guest: isGuest.toString(),
        })
        submitGrantingAccess()
        break
      case 'EMAIL_NOT_VERIFIED':
        dispatchWebViewAction({
          action: 'push',
          url: resolveURL('/email_validation', {
            user_id: payload.id,
            from: '/signin',
          }).toString(),
          navBarTitle: $_('signin_title'),
          navBarVisible: true,
        })
        break
      case 'NETWORK_ERROR':
        showDialog(Dialog.InternetDisconnected)
        break
      case 'WRONG_PASSWORD':
        showDialog(Dialog.WrongPassword)
        break
      case 'UNHANDLED_ERROR':
        showDialog(Dialog.UnhandledError(payload.message))
        break
      case 'UNHANDLED_HTTP_ERROR':
        showDialog(Dialog.UnhandledHTTPError(payload))
        break
    }
  }
</script>

<form class="flex h-full flex-col" on:submit|preventDefault={handleSubmit}>
  <Main>
    <p class="text-content-secondary text-body-lg">
      {$_('signin_enter_password', {
        values: {
          email: params.get('email'),
        },
      })}
    </p>
    <TextField
      type="password"
      name="password"
      schema={SignInForm.shape.password}
      dataFieldName="login_password"
    />
    <div class="text-right text-body-md">
      <ForgotPasswordButton />
    </div>
  </Main>
  <Footer>
    <button
      type="submit"
      class="btn"
      disabled={isLoading || !allTouched || hasError}
      on:click={() => {
        logComponentEvent({
          type: 'btn',
          appAction: 'click',
          componentName: 'login',
        })
      }}
    >
      {#if isLoading}
        <Spinner />
      {/if}
      <span>{$_('btn_next')}</span>
    </button>
  </Footer>
</form>
