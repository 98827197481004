import { z } from 'zod'

export const PeekEmailForm = z.object({
  email: z
    .string()
    .email('onboarding_account_format_error')
    .min(1, 'empty_field_error'),
})

export const SignUpForm = z.object({
  username: z.string().max(30).min(1, 'empty_field_error'),
  password: z
    .string()
    .refine((val) => {
      if (val.length < 8) return false
      const hasLetter = /[A-Za-z]/.test(val)
      const hasNumber = /[0-9]/.test(val)
      return hasLetter && hasNumber
    }, 'password_format_error')
    .refine((val) => val.length > 0, 'empty_field_error'),
  confirm_password: z.string().min(1, 'empty_field_error'),
  birthday: z.string().min(1, 'empty_field_error'),
})

export const SignUpFormRefined = SignUpForm.refine(
  (data) => data.password === data.confirm_password,
  {
    message: 'confirm_password_error',
    path: ['confirm_password'],
  },
).refine((data) => data.confirm_password.length > 0, {
  message: 'empty_field_error',
  path: ['confirm_password'],
})

export const SignInForm = z.object({
  password: z.string().min(1, 'empty_field_error'),
})

export const EmailValidationForm = z.object({
  code: z.string().min(1, 'empty_field_error'),
})

export const ChangeEmailForm = z.object({
  newEmail: z
    .string()
    .email('onboarding_account_format_error')
    .min(1, 'empty_field_error'),
  password: z.string().min(1, 'empty_field_error'),
})

export const ChangePasswordForm = z.object({
  originalPassword: z.string().min(1, 'empty_field_error'),
  newPassword: z
    .string()
    .refine((val) => {
      if (val.length < 8) return false
      const hasLetter = /[A-Za-z]/.test(val)
      const hasNumber = /[0-9]/.test(val)
      return hasLetter && hasNumber
    }, 'password_format_error')
    .refine((val) => val.length > 0, 'empty_field_error'),
  confirmNewPassword: z.string().min(1, 'empty_field_error'),
})

export const ChangePasswordFormRefined = ChangePasswordForm.refine(
  (data) => data.newPassword === data.confirmNewPassword,
  {
    message: 'confirm_password_error',
    path: ['confirmNewPassword'],
  },
)

export const DeleteAccountForm = z.object({
  password: z.string().min(1, 'empty_field_error'),
})

export const ResetPasswordForm = z.object({
  code: z.string().min(1, 'empty_field_error'),
  newPassword: z
    .string()
    .refine((val) => {
      if (val.length < 8) return false
      const hasLetter = /[A-Za-z]/.test(val)
      const hasNumber = /[0-9]/.test(val)
      return hasLetter && hasNumber
    }, 'password_format_error')
    .refine((val) => val.length > 0, 'empty_field_error'),
  confirmNewPassword: z.string().min(1, 'empty_field_error'),
})

export const ResetPasswordFormRefined = ResetPasswordForm.refine(
  (data) => data.newPassword === data.confirmNewPassword,
  {
    message: 'confirm_password_error',
    path: ['confirmNewPassword'],
  },
)

export const AgeScreeningForm = z.object({
  code: z.string().min(1, 'empty_field_error'),
})
