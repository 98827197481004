<script lang="ts">
  import Cell from '@/components/cell.svelte'
  import Username from '@/components/username.svelte'
  import { sendAccountActionMessage } from '@/lib/native-messaging/account-action'
  import { Dialog, showDialog } from '@/lib/native-messaging/dialog'
  import {
    dismissLoadingView,
    showLoadingView,
  } from '@/lib/native-messaging/loading-view'
  import { dispatchWebViewAction } from '@/lib/native-messaging/web-view-action'
  import { audience } from '@/params'

  export let user = {
    id: '',
    name: 'John Doe',
    email: 'email@address.com',
    ssoProvider: 'seekrauth',
  } satisfies {
    id: string
    name: string
    email: string
    ssoProvider: 'seekrauth' | 'google' | 'apple'
  }

  async function signOut() {
    showLoadingView()
    try {
      const res = await fetch('/logout', { method: 'DELETE' })
      if (!res.ok) throw res.status
      sendAccountActionMessage({ action: 'sign_out' })
      dispatchWebViewAction({ action: 'end_session' })
    } catch (err) {
      if (err instanceof TypeError) {
        showDialog(Dialog.InternetDisconnected)
        return
      }
    } finally {
      dismissLoadingView()
    }
  }

  function handleLogoutCellClick() {
    switch (audience) {
      case 'seekrtech.forest':
      case 'upwardsware.forest':
        showDialog(Dialog.ForestSignOut, (action) => {
          if (action === Dialog.ForestSignOut.primaryBtnAction) {
            signOut()
          }
        })
        break
      default:
        showDialog(Dialog.SignOut, (action) => {
          if (action === Dialog.SignOut.primaryBtnAction) {
            signOut()
          }
        })
        break
    }
  }

  document.documentElement.style.backgroundColor = 'var(--bg-secondary)'
</script>

<div class="h-full space-y-4 bg-bg-secondary px-5 pt-3">
  <div class="space-y-2">
    <Username username={user.name} userId={user.id} />
    <div class="text-content-secondary text-body-md">{user.email}</div>
  </div>
  <div class="flex flex-col overflow-hidden rounded-[16px] bg-white">
    <Cell
      componentName="edit_email"
      titleKey="manage_account_change_email"
      pathname="/change_email"
      disabled={user.ssoProvider !== 'seekrauth'}
    />
    <Cell
      componentName="edit_password"
      titleKey="manage_account_change_password"
      pathname="/change_password"
      disabled={user.ssoProvider !== 'seekrauth'}
    />
    <Cell
      componentName="logout"
      titleKey="manage_account_log_out"
      action={handleLogoutCellClick}
    />
    <Cell
      componentName="delete_account"
      titleKey="manage_account_delete_account"
      pathname="/delete_account"
      isDanger
    />
  </div>
</div>
