<script lang="ts">
  import Spinner from '@/components/anim/spinner.svelte'
  import ResendSection from '@/components/resend-section.svelte'
  import TextField from '@/components/text-field.svelte'
  import Footer from '@/layouts/footer.svelte'
  import Main from '@/layouts/main.svelte'
  import { grantAccess, resetPassword } from '@/lib/api'
  import { sendAccountActionMessage } from '@/lib/native-messaging/account-action'
  import { Dialog, showDialog } from '@/lib/native-messaging/dialog'
  import { Toast, showToast } from '@/lib/native-messaging/toast'
  import { dispatchWebViewAction } from '@/lib/native-messaging/web-view-action'
  import { redirect } from '@/lib/oauth/redirect'
  import { redirectUri } from '@/params'
  import { ResetPasswordForm, ResetPasswordFormRefined } from '@/schema'
  import type { FormStatus } from '@/types/form'
  import { setContext } from 'svelte'
  import { _ } from 'svelte-i18n'
  import { writable } from 'svelte/store'

  const params = new URLSearchParams(window.location.search)
  const email = params.get('email')

  let isLoading = false

  const formStore = writable<FormStatus>({})
  setContext('formStore', formStore)

  $: allTouched = Object.values($formStore).every((status) => status.touched)
  $: hasError = Object.values($formStore).some((status) => !!status.error)

  async function submitGrantingAccess() {
    isLoading = true
    const [result, payload] = await grantAccess()
    isLoading = false
    if (result !== 'SUCCESS') {
      showDialog(Dialog.RetryDialog, (action) => {
        if (action === Dialog.RetryDialog.primaryBtnAction) {
          submitGrantingAccess()
        }
      })
      return
    }
    redirect(redirectUri, { ...Object.fromEntries(params), ...payload })
    dispatchWebViewAction({ action: 'dismiss' })
  }

  async function handleSubmit(
    event: SubmitEvent & {
      currentTarget: EventTarget & HTMLFormElement
    },
  ) {
    if (!email) {
      return alert('`email` not set as query parameter')
    }
    const parsed = ResetPasswordFormRefined.safeParse(
      Object.fromEntries(new FormData(event.currentTarget)),
    )
    if (parsed.success === false) {
      parsed.error.errors.forEach((error) => {
        const [path] = error.path
        formStore.update((status) => {
          return {
            ...status,
            [path]: {
              touched: true,
              error: $_(error.message),
            },
          }
        })
      })
      return
    }

    isLoading = true
    const [result, payload] = await resetPassword({
      email,
      code: parsed.data.code,
      newPassword: parsed.data.newPassword,
    })
    isLoading = false

    switch (result) {
      case 'SUCCESS':
        showToast(Toast.ResetPasswordSuccess)
        sendAccountActionMessage({ action: 'reset_password' })
        submitGrantingAccess()
        break
      case 'INVALID_CODE':
        showDialog(Dialog.WrongVerificationCode)
        break
      case 'NETWORK_ERROR':
        showDialog(Dialog.InternetDisconnected)
        break
      case 'UNHANDLED_HTTP_ERROR':
        showDialog(Dialog.UnhandledHTTPError(payload))
        break
      case 'UNHANDLED_ERROR':
        showDialog(Dialog.UnhandledError(payload.message))
        break
    }
  }
</script>

<form class="flex h-full flex-col" on:submit|preventDefault={handleSubmit}>
  <Main>
    <p class="text-content-secondary text-body-lg">
      {$_('verification_code_sent', {
        values: {
          email: email,
        },
      })}
    </p>
    <TextField
      name="code"
      placeholder={$_('placeholder_verification_code')}
      schema={ResetPasswordForm.shape.code}
      dataFieldName="reset_verification"
    />
    <TextField
      type="password"
      name="newPassword"
      placeholder={$_('placeholder_new_password')}
      schema={ResetPasswordForm.shape.newPassword}
      dataFieldName="new_password"
    />
    <TextField
      type="password"
      name="confirmNewPassword"
      placeholder={$_('placeholder_confirm_password')}
      schema={ResetPasswordForm.shape.confirmNewPassword}
      dataFieldName="new_password_confirm"
    />
    <ResendSection tokenType="reset_password" hasSentEmailFromServer={false} />
  </Main>
  <Footer>
    <button class="btn" disabled={isLoading || !allTouched || hasError}>
      {#if isLoading}
        <Spinner />
      {/if}
      <span>
        {$_('btn_reset_password')}
      </span>
    </button>
  </Footer>
</form>
